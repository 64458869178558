import { Component } from '@angular/core';
import { ScreenSizeService } from 'src/app/screen-size.service';
import { CottageInformation } from './cottageInformation';

@Component({
	selector: 'app-steinbruchhhaeusel',
	templateUrl: './steinbruchhhaeusel.component.html',
	styleUrls: ['./steinbruchhhaeusel.component.scss']
})
export class SteinbruchhhaeuselComponent {
	isDesktop: boolean;
	introImage: string = '..//../../assets/images/steinbruchhaus_hausansicht.jpg';
	panelHeaderTextColor = 'white';

	cottageInformations: CottageInformation[] = [
		new CottageInformation('..//../../assets/images/steinbruchhaus_hausansicht.jpg', 'Foto Hausansicht'),
		new CottageInformation('..//../../assets/images/steinbruchhaus_kueche.jpg', 'Küche'),
		new CottageInformation('..//..//../assets/images/steinbruchhaus_ofen.jpg', 'Wohnbereich mit Ofen'),
		new CottageInformation('..//..//../assets/images/steinbruchhaus_treppe.jpg', 'Treppe'),
		new CottageInformation('', 'Badeansichten'),
		new CottageInformation('..//..//../assets/images/steinbruchhaus_betten.jpg', 'Schlafzimmer'),
		new CottageInformation('..//..//../assets/images/steinbruchhaus_front.jpg', 'Außenbereich'),
		new CottageInformation('', 'Wohnwagen (außen und innen'),
		new CottageInformation('..//..//../assets/images/steinbruchhaus_esstisch.jpg', 'Esstisch im Wohnbereich'),
	]

	constructor(private screenSizeService: ScreenSizeService) {
		this.screenSizeService.screenSizeObserver$.subscribe(isDesktop => { this.isDesktop = isDesktop; });
	}

	changeColor(): void {
		if (this.panelHeaderTextColor === '#ffc107')
			this.panelHeaderTextColor = 'white'
		else
			this.panelHeaderTextColor = '#ffc107';
	}
}
