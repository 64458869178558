import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private screenSizeObserver: Observable<boolean> = new BehaviorSubject(true);

  get screenSizeObserver$() {
    return this.screenSizeObserver
  }

  constructor() { }

  updateScreenSize(isDesktop: boolean) {
    (this.screenSizeObserver as BehaviorSubject<boolean>).next(isDesktop);
  }
}
