import { Component } from '@angular/core';
import { ScreenSizeService } from 'src/app/screen-size.service';

@Component({
	selector: 'app-balkenbetten',
	templateUrl: './balkenbetten.component.html',
	styleUrls: ['./balkenbetten.component.scss']
})
export class BalkenbettenComponent {
	isDesktop: boolean;

	constructor(private screenSizeService: ScreenSizeService) {
		this.screenSizeService.screenSizeObserver$.subscribe(isDesktop => {
			this.isDesktop = isDesktop;
		});
	}
}
