import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spotlight',
  templateUrl: './spotlight.component.html',
  styleUrls: ['./spotlight.component.scss']
})
export class SpotlightComponent {

  constructor() { }

  @Input()
  headline: string;

  @Input()
  description: string;

  @Input()
  link: string[];

  @Input()
  imageSource: string;
}
