import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ScreenSizeService } from './screen-size.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Zimmerei am Berge';
  isDesktop: boolean;

  constructor(private breakPointObserver: BreakpointObserver, private screenSizeService: ScreenSizeService, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.breakPointObserver
      .observe(['(min-width: 570px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches)
          this.isDesktop = true;
        else
          this.isDesktop = false;

        console.log('is Desktop', this,this.isDesktop);
        this.screenSizeService.updateScreenSize(this.isDesktop);
      });
  }
}
