import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BodyComponent } from './Body/body/body.component';
import { UeberUnsComponent } from './Body/ueber-uns/ueber-uns.component';
import { KontaktComponent } from './Body/kontakt/kontakt.component';
import { ImpressumComponent } from './Body/impressum/impressum.component';
import { DaecherComponent } from './Body/referenzen/daecher/daecher.component';
import { SteinbruchhhaeuselComponent } from './Body/referenzen/steinbruchhhaeusel/steinbruchhhaeusel.component';
import { BalkenbettenComponent } from './Body/referenzen/balkenbetten/balkenbetten.component';

const routes: Routes = [
	{ path: 'startPage', component: BodyComponent },
	{ path: '', redirectTo: '/startPage', pathMatch: 'full' },
	{ path: 'daecher', component: DaecherComponent, pathMatch: 'prefix'},
	{ path: 'steinbruchhaeusel', component: SteinbruchhhaeuselComponent },
	{ path: 'ueberUns', component: UeberUnsComponent },
	{ path: 'kontakt', component: KontaktComponent },
	{ path: 'impressum', component: ImpressumComponent },
	{ path: 'balkenBetten', component: BalkenbettenComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
