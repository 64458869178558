import { Component } from '@angular/core';
import { ScreenSizeService } from 'src/app/screen-size.service';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent {
  isDesktop: boolean;

  constructor(private screenSizeService: ScreenSizeService) {
    this.screenSizeService.screenSizeObserver$.subscribe(isDesktop => {this.isDesktop = isDesktop;});
  }
}
